.register-page .squares,
.register-page .square,
.section.section-signup .squares,
.section.section-signup .square {
  background: linear-gradient(0deg, #315df4 0%, #2677f6 100%);
}
.alert {
  margin: auto;
  margin-top: 3em;
  position: absolute;
  z-index: 100443;
  width: auto;
  width: 92%;

  margin-left: 4%;
  white-space: pre;
}
#loading-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.dark-blur {
  filter:brightness(70%) blur(1px);
  opacity: 0.3;
}

#loading-card {
  width: 22em;
}
.register-bg {
  height: 700px;
  background: linear-gradient(to bottom, transparent 0%, #1f2251 100%);
  width: 700px;
  position: relative;
  right: -600px;
  top: -750px;
  border-radius: 20%;
  transform: rotate(
-15deg
);
}
.text-centered{
  text-align: center;
}
@media screen and (max-width: 992px) {
  .none-md{
    display: none;
  }
  .margin-md-0{
    margin:0px;
  }
  .margin-top-md-3{
    margin-top: 3em;
  }
  .position-md-relative{
    position: relative!important;
  }
  .height-md-100{
    height:100%!important;
  }
  .height-md-400px{
    height:400px!important;
  }
  ::-webkit-scrollbar-track{
    display: none;
  }
}

.background-image-cover{
  background-size: 100%;
}
video{
  outline: 0px;
  object-fit: cover;
}
.chat-title{
  border-bottom: solid;
  border-width: 1px;
  border-color: #1d8cf8;
  padding:1em;
  color:#1d8cf8;
  font-weight: bold;
}
.chat-body{
  overflow-y: auto;
  height: 90%;
}
::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	border-radius: 10px;
	background-color: transparent;
}

::-webkit-scrollbar
{
	width: 5px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgb(29, 140, 248);;
}
.admin-input{
  border: 1px solid #cad1d7!important;
  border-radius: 0.4285rem!important;
  opacity: 1!important;
  /* height:4em!important; */
  position: relative!important;
}

a,a:hover, a:focus {
  color: #227ef7;
}
.footer{
  border-color:#227ef7;
}
.alert{
  white-space: normal;
}

.CardField-child{
  transform: translateX(-88px);
    width: 6em;
}

.navbar.navbar-transparent{
  background-color:#227ef7!important ;
}
.btn-success{
  background-image: none;
  background: #00cfa7!important;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success:active:focus, .btn-success:active:hover, .btn-success.active:focus, .btn-success.active:hover {
  background-color:  #00cfa7!important;
  background-image: none !important
}
#loading-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

#loading-card {
  width: 22em;
}
